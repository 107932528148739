import '../App.css';
import NavBar from '../pages/section/NavBar';
import Footer from '../pages/section/Footer';
import Body from '../pages/section/Body';

function Demo() {
  return (
    <div>
      <NavBar/>
      <Body/>
      <Footer/>
    </div>
  );
}

export default Demo;