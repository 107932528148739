import '../../App.css';

function Body() {
  return (
    <div>
        <div id="prz-body" className="bg-light rounded">
            <div className="col-lg-10 col-md-10 col-sm-12 col-xm-12 mx-auto pt-4 pb-4">
                <div className="text-center mb-1"><h1>Workflow Demo</h1></div>

                <div id="preezie-widget-div-id"></div>
                <div id="preezie-widget-div-id-popup"></div>
                
            </div>
        </div>
    </div>
  );
}

export default Body;
