import '../../App.css';

function Footer() {
  return (
    <div>
      <footer id="prz-footer" className="bd-footer py-5 mt-5 bg-warning.bg-gradient">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-1 col-md-12"></div>
            <div className="col-lg-3 col-md-12 text-center text-md-start">
              <a
                className="d-inline-flex align-items-center mb-2 link-dark text-decoration-none"
                href="/"
                aria-label="Bootstrap"
              >
                <span className="fs-5">preezie</span>
              </a>
              <ul className="list-unstyled small text-muted">
                <li className="mb-2">
                  Shop online like you do in-store with a "guided shopping"
                  retail partner.
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-2 col-md-12 text-center text-md-start">
              <h5>About Us</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a className="link-dark" href="https://www.preezie.com/company" target="_blank" rel="noreferrer">
                    Company
                  </a>
                </li>
                <li className="mb-2">
                  <a className="link-dark" href="https://www.preezie.com/blog" target="_blank" rel="noreferrer">
                    Blog
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="link-dark"
                    href="https://www.preezie.com/case-studies"
                    target="_blank" rel="noreferrer"
                  >
                    Case Studies
                  </a>
                </li>
                <li className="mb-2">
                  <a className="link-dark" href="https://www.preezie.com/contact" target="_blank" rel="noreferrer">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-12 text-center text-md-start">
              <h5>Help</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="link-dark"
                    href="https://www.preezie.com/frequently-asked-questions" target="_blank" rel="noreferrer"
                  >
                    FAQ
                  </a>
                </li>
                <li className="mb-2">
                  <a className="link-dark" href="https://www.preezie.com/docs" target="_blank" rel="noreferrer">
                    Documentation
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-12 text-center text-md-start">
              <h5>Follow Us</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="link-dark"
                    href="https://www.facebook.com/preezieAU/" target="_blank" rel="noreferrer"
                  >
                    FaceBook
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="link-dark"
                    href="https://twitter.com/preezieofficial?lang=en" target="_blank" rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="link-dark"
                    href="https://www.linkedin.com/company/preezie/mycompany/" target="_blank" rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-between py-4 my-4 border-top">
            <p>© 2022 preezie</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
