import "../../App.css";

function NavBar() {

  let urlCms = document.location.hostname.startsWith("test")
    ? "https://test-ui-cms.azurewebsites.net/"
    : "https://admin.preezie.com/";

  return (
    <div>
      <nav
        id="prz-navbar"
        className="navbar navbar-expand-lg navbar-light bg-light shadow-sm"
        aria-label="Eighth navbar example"
      >
        <div className="container">
          <a className="navbar-brand mt-4 mb-4" href="https://preezie.com/" target="_blank" rel="noreferrer">
            <img
              src="https://www.preezie.com/hubfs/img/logo.svg"
              alt=""
              width="200"
              height="42.09"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample07"
            aria-controls="navbarsExample07"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsExample07">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <form>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <div className="btn btn-block btn-social btn-linkedin">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>{" "}
                    <a
                      className="link-dark"
                      href="https://www.linkedin.com/company/preezie/mycompany/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="btn btn-block btn-social btn-linkedin">
                    <i className="fa fa-location-arrow" aria-hidden="true"></i>{" "}
                    <a
                      className="link-dark"
                      href="https://www.preezie.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      preezie
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="btn btn-block btn-social btn-linkedin">
                    <i className="fa fa-desktop" aria-hidden="true"></i>{" "}
                    <a
                      className="link-dark"
                      href={urlCms}
                      target="_blank"
                      rel="noreferrer"
                    >
                      preezie CMS
                    </a>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
