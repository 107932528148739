import './App.css';
import Demo from './pages/Demo';

function App() {
  return (
    <div className="App">
      <Demo/> 
    </div>
  );
}

export default App;
